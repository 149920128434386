<template>
  <div>
    <img :src="imageUrl" width="222" height="222" style="border: 1px solid red" />
    <div ref="wrapper" class="wrapper">
      <canvas ref="signaturePad" class="signature-pad" style="border: 1px solid red"></canvas>
    </div>

    <button @click="saveAsPNG">Save as PNG</button>
    <button @click="setDrawMode">Draw</button>
    <button @click="setEraseMode">Erase</button>
    <button @click="clearCanvas">Clear</button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import SignaturePad from 'signature_pad';

onMounted(() => {
  sessionStorage.removeItem('selected_member');
})
const imageUrl = ref('');
const wrapper = ref<HTMLElement | null>(null);
const signaturePad = ref<SignaturePad | null>(null);

onMounted(() => {
  const canvas = wrapper.value?.querySelector('canvas');
  if (canvas) {
    // Get the actual size of the wrapper
    const rect = wrapper.value?.getBoundingClientRect();
    if (rect) { // Check if rect is not null or undefined
      // Set canvas width and height
      canvas.width = rect.width;
      canvas.height = rect.height;
    }
    signaturePad.value = new SignaturePad(canvas, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(1, 2, 3)'
    });
  }
});


const saveAsPNG = () => {
  if (signaturePad.value?.isEmpty()) {
    alert("Please provide a signature first.");
    return;
  }
  const data = signaturePad.value?.toDataURL('image/png');
  if (data) {
    imageUrl.value = data;
  }
};

const setDrawMode = () => {
  const canvas = wrapper.value?.querySelector('canvas');
  if (canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.globalCompositeOperation = 'source-over';
    }
  }
};

const setEraseMode = () => {
  const canvas = wrapper.value?.querySelector('canvas');
  if (canvas) {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.globalCompositeOperation = 'destination-out';
    }
  }
};

const clearCanvas = () => {
  signaturePad.value?.clear();
};
</script>

<style>
.wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%; /* Adjust to the actual size of the wrapper */
  height: 100%; /* Adjust to the actual size of the wrapper */
  background-color: white;
}
</style>
