<template>
  <v-row>
    <v-col cols="1" style="min-width:210px;">
      <v-sheet id="BasicConfigLeftNavi" rounded="lg">
        <v-list rounded="lg">
          <v-divider class="my-2"></v-divider>
          <v-list-item
            rounded="lg"
            v-for="item in lists"
            :key="item.title"
            @click="loadComponent(item.link); setActiveItem(item)"
            :class="{ 'active-link': isActiveLink(item.link) }"
          >
            {{ item.title }}
          </v-list-item>
          <v-divider class="my-2"></v-divider>
        </v-list>
      </v-sheet>
    </v-col>
    <v-col>
      <v-sheet rounded="lg">
        <!-- dynamicComponent 변수를 사용하며 디폴트 값으로 LaborCost를 설정 -->
        <component :is="dynamicComponent"></component>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { shallowRef, onMounted } from 'vue';

// dynamicComponent 변수를 shallowRef로 선언하고 디폴트 값으로 'EtcLaborCost'를 설정
const dynamicComponent = shallowRef('LaborCost');
const activeItem = shallowRef('LaborCost');

const lists = [
  { title: '인건비 지출비율', link: 'LaborCost' },
  { title: 'W4C 계정코드 매칭', link: 'W4CMatch'},
  { title: '수가관리', link: 'SugaCtrl'},
  { title: '사회보험요율관리', link: 'SocialCtrl'},
  { title: '유급휴일관리', link: 'HolidayCtrl'},
  { title: '관항목관리', link: 'CateCtrl'},
];

const loadComponent = async (componentName: string) => {
  // 비동기적으로 컴포넌트를 불러옴
  const { default: Component } = await import(`@/components/etc_config/Etc${componentName}.vue`);

  // 불러온 컴포넌트를 동적으로 설정
  dynamicComponent.value = Component;
};

const setActiveItem = (item: any) => {
  activeItem.value = item.link;
};

const isActiveLink = (link: string) => {
  return activeItem.value === link;
};
onMounted(() => {
  loadComponent(dynamicComponent.value);
});
</script>

