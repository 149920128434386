<template>
  <div id="MemberInfoRightCard">
      <v-btn id="MemberInfoRightCardBtn"
       variant="text"
        v-for="section in sections"
        :key="section.title"
          class="align-self-center me-4"
          @click="loadComponent(section.link); setActiveItem(section)"
          :class="{ 'v-btn--active': isActiveLink(section.link) }"
        >
          {{ section.title }}
      </v-btn>
    </div>
  <v-card>
    <v-row>
      <v-col cols="12">
        <v-sheet rounded="lg">
        <!-- dynamicComponent 변수를 사용하며 디폴트 값으로 LaborCost를 설정 -->
        <component :is="dynamicComponent"></component>
      </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup lang="ts">
import { onMounted, shallowRef } from 'vue';

const dynamicComponent = shallowRef('MemberInfoMenu1');
const activeItem = shallowRef('MemberInfoMenu1');

const sections = [
  { title: '메뉴1', link: 'MemberInfoMenu1' },
  { title: '메뉴2', link: 'MemberInfoMenu2' },
  { title: '메뉴3', link: 'MemberInfoMenu3' },
  { title: '메뉴4', link: 'MemberInfoMenu4' },
];
const loadComponent = async (componentName: string) => {
  // 비동기적으로 컴포넌트를 불러옴
  const { default: Component } = await import(`@/components/member/${componentName}.vue`);

  // 불러온 컴포넌트를 동적으로 설정
  dynamicComponent.value = Component;
};

const setActiveItem = (item: any) => {
  activeItem.value = item.link;
};

const isActiveLink = (link: string) => {
  return activeItem.value === link;
};


onMounted(() => {
  loadComponent(dynamicComponent.value);
});
</script>