<template v-if="isAdmin">
<v-container style="text-align: center; height: calc(70vh + 47px);">
  
  <h3>
    {{ memberFormData.mb_name }}
  </h3>
</v-container>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import store from '../../store';
import { MemberInfo } from '../../../types'; // types.ts 파일에서 가져옴

const router = useRouter();
const isAdmin = computed(() => store.getters.isAdmin);

router.beforeEach((to: any, from: any, next: any) => {
  if (to.name === 'AdminMain' && !isAdmin.value) {
    alert('관리자 권한이 필요합니다.');
    next({ name: 'MemberInfoDetail' });
  } else {
    next();
  }
});

// memberFormDataList를 reactive로 선언하고 초기화
const memberFormDataList = ref<MemberInfo[]>([]);

// memberFormData를 reactive로 선언
const memberFormData = reactive<MemberInfo>({
mbidx: 0, // 예시에서는 mbidx를 0으로 초기화, 실제 초기값에 따라 수정 필요
mb_id: '',
mb_passwd: '',
mb_name: '',
mb_resno: null,
mb_regnb: '',
ltc_orgnb: '',
mb_email: '',
mb_phone: '',
mb_fax: '',
mb_post: '',
mb_address1: '',
mb_address2: '',
bank: '',
depositor: '',
bank_account: '',
head_name: '',
head_mbphone: '',
head_post: null,
head_address1: null,
head_address2: null,
head_hmphone: '',
chief_name: null,
chief_mbphone: null,
chief_hmphone: null,
paym_name: null,
paym_mbphone: null,
paym_hmphone: null,
income_name: null,
outcome_name: null,
mng_id: '',
mng_com: '',
trans_class: null,
svc_cms: '',
sisul: '',
mb_auth: '',
memo: '',
status: '',
ins_st_date: '',
data_status: '',
ins_homeurl: '',
ins_limit: '',
rdate: '',
wdate: null,
holiday: '',
svc_date: '',
svc_end_date: null,
message: '',
not_sisul: ''
});

// GetMemberInfoDetail 함수 수정
const GetMemberInfoDetail = () => {
  const memberInfoDetail = store.getters.getMemberInfoDetail;

  if (Array.isArray(memberInfoDetail) && memberInfoDetail.length > 0) {
    // memberInfoDetail가 배열이고 길이가 1 이상인 경우
    memberFormDataList.value = [...memberInfoDetail];
    Object.assign(memberFormData, memberInfoDetail[0]);
  } else if (memberInfoDetail && typeof memberInfoDetail === 'object') {
    // memberInfoDetail이 객체인 경우 (하나의 멤버 정보만 있을 때)
    memberFormDataList.value = [memberInfoDetail];
    Object.assign(memberFormData, memberInfoDetail);
  }
  console.log(memberFormData);
};

onMounted(() => {
  GetMemberInfoDetail();
});
</script>
