<template>
    <v-main class="blue-grey lighten-4">
      <v-container class="login_container" >
            <v-card class="login_card" elevation="3"> <!--elevation : 그림자-->
              <div class="pa-10">
                <h1 style="text-align: center" class="mb-10">Login</h1>
                <v-form action="submit" method="post" @submit.prevent="login">
                  <v-text-field :prepend-inner-icon="mdiAccount" variant="outlined" label="ID" type="text" id="loginId" v-model="form.loginId" autocomplete="username" />
                  <v-text-field :prepend-inner-icon="mdiLock" variant="outlined" label="PW" hint="Enter your password to access this website" type="password" id="loginPw" v-model="form.loginPw" autocomplete="current-password" />
                  <v-btn
                    type="submit"
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                    class="mb-3"
                  >
                    LOGIN
                  </v-btn>
                  <v-btn
                    color="blue lighten-1 text-capitalize"
                    depressed
                    large
                    block
                    dark
                  >
                    Sign Up
                  </v-btn>
                </v-form>
              </div>
            </v-card>
      </v-container>
    </v-main>
    
</template>

<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import router from '../../router';
import store from '../.././store';
import { mdiAccount,mdiLock } from '@mdi/js'

function formatStartDate(inputDate: any) {
  const date = new Date(inputDate);
  const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
  // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
  if (newDate.getMonth() === -1) {
      newDate.setFullYear(date.getFullYear() - 1);
      newDate.setMonth(11); // 12월로 설정
  }
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}-01`;
}
function formatEndDate(inputDate: any) {
  const date = new Date(inputDate);
  const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
  // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
  if (newDate.getMonth() === -1) {
      newDate.setFullYear(date.getFullYear() - 1);
      newDate.setMonth(11); // 12월로 설정
  }
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const daysInMonth = new Date(year, date.getMonth() + 0, 0).getDate();
  console.log(daysInMonth,'daysInMonth')
  return `${year}-${month}-${daysInMonth}`;
}



const mainStartDate = ref(formatStartDate(new Date().toISOString())); // 현재 날짜의 yyyy-mm-dd 형식
const mainEndDate = ref(formatEndDate(new Date().toISOString())); // 현재 날짜의 yyyy-mm-dd 형식

const account = ref({
  id: null,
  name: '',
  role: '',
});

const form = ref({
  loginId: '',
  loginPw: ''
});

const login = () => {
  const args = {
    loginId: form.value.loginId,
    loginPw: form.value.loginPw
  };

  axios.post("/api/account", args).then(async (res:any) => {
      console.log((mainStartDate.value) , '~' , (mainEndDate.value))

      account.value = res.data;
      await store.dispatch('login', { role: res.data.role, name: res.data.name });

      store.commit('setMainStartDate',mainStartDate.value);
      store.commit('setMainEndDate',mainEndDate.value);
      store.commit('setMainComp', null);

      router.push({
        path: "/",
      });
    })
    .catch(() => {
      alert("로그인에 실패했습니다. 계정 정보를 확인해주세요.");
    });

};



// onMounted(async () => {
//   try {
//     const res = await axios.get("/api/account");
//     account.value = res.data;
//   } catch (error) {
//     return;
//   }
//   const args = {
//     loginId: form.value.loginId,
//     loginPw: form.value.loginPw
//   };
//   axios
//     .post("/api/account", args)
//     .then((res:any) => {
//       account.value = res.data;
//       store.dispatch('login', { role: res.data.role });
//       store.dispatch('logout', { role: res.data.role });
//     });
// });
</script>



