<template v-if="isAdmin">
  <v-container id="AdminPageContainer">
    <MemberInfo/>
  </v-container>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import store from '../../store';
import MemberInfo from '../../components/member/MemerInfo.vue';


const router = useRouter();

const isAdmin = computed(() => store.getters.isAdmin);

router.beforeEach((to:any, from:any, next:any) => {
  if (to.name === 'AdminMain' && !isAdmin.value) {
    alert('관리자 권한이 필요합니다.');
    next({ name: 'indexPage' });
  } else {
    next();
  }
});
</script>