<template>
    <v-table class="adminComponentTable">
      <tbody>
        <tr>
          <td>컴포넌트2</td>
          <td row="3">이모티콘</td>
        </tr>
        <tr>
          <td>컴포넌트2</td>
        </tr>
        <tr>
          <td>컴포넌트2</td>
        </tr>
      </tbody>
    </v-table>
</template>