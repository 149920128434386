<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>권고사항 및 점검내용 설정</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items style="align-items: center;">
        [ 저장: ctrl + Enter ]
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn text="Save" variant="text" @click="rpmWrite">Save</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div style="width: 100%; background-color: #323639; display: flex;">
      <iframe v-if="pdfPreviewUrl" :src="pdfPreviewUrl"
        :style="{ width: pdfWidth, height: 'calc(100vh - 80px)', border: 'none' }"></iframe>
      <div 
        class="resizer" 
        @mousedown="startResize"
        style="width: 10px; cursor: col-resize; background-color: #aaa;">
      </div>
      <div style="display: flex; flex-direction: column;" :style="{ width: textWidth }">
        <div class="fontNotoSans700" style="width: 100%;"
          :style="{ height: isSectionVisible ? 'auto' : '24px', margin: '20px', padding: '0 5px 10px 10px', backgroundColor: '#fff' }">
          <p @click="toggleSection" style="cursor: pointer">
            {{ isSectionVisible ? '닫기 ▲' : '자동생성 멘트  ▼ ' }}
          </p>
          <br>
          {{ isSectionVisible ? '※ 제목·내용이 저장된 후 w4c인건비를 변경했을 시 참고해주세요. ': '' }}
          <br>
          <br>
          {{ isSectionVisible ? '제목: '+defaultTitle : '' }}
          <br>
          <br>
          {{ isSectionVisible ? '내용: '+onmountLaborCalc : '' }}
        </div>
        <textarea v-model="rpmTitleInfo" placeholder="제목을 작성해주세요."
          style="width: 100%; font-weight: 700; height: calc(45px); margin: 0 20px 0px 20px; padding: 10px 10px; background-color: #fff; border: 1px solid #ccc; border-radius: 5px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
        </textarea>
        <textarea v-model="rpmInfo" placeholder="권고사항 및 점검내용을 작성해주세요."
          style="width: 100%; height: calc(100vh - 200px); margin: 0 20px 0px 20px; padding: 15px 10px; background-color: #fff; border: 1px solid #ccc; border-radius: 5px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
        </textarea>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref, watch, onBeforeUnmount, onUnmounted } from 'vue';
import store from '@/store';
const pdfWidth = ref('calc(50% - 10px)');
const textWidth = ref('calc(50% - 40px)');
let startX = 0;
let startPdfWidth = 0;

const startResize = (event:any) => {
  startX = event.clientX;
  startPdfWidth = event.target.previousElementSibling.offsetWidth;
  document.addEventListener('mousemove', resize);
  document.addEventListener('mouseup', stopResize);
};

const resize = (event:any) => {
  const dx = event.clientX - startX;
  const newPdfWidth = startPdfWidth + dx;
  const newTextWidth = window.innerWidth - newPdfWidth - 20; // Adjust for margins and resizer width
  pdfWidth.value = `${newPdfWidth}px`;
  textWidth.value = `${newTextWidth}px`;
};

const stopResize = () => {
  document.removeEventListener('mousemove', resize);
  document.removeEventListener('mouseup', stopResize);
};

onUnmounted(() => {
  document.removeEventListener('mousemove', resize);
  document.removeEventListener('mouseup', stopResize);
});

const pdfPreviewUrl = ref('');
const isSectionVisible = ref(false);

const toggleSection = () => {
  isSectionVisible.value = !isSectionVisible.value;
}
const previewPdf = async () => {
  try {
    await FnARptInfo();
    const pdfData = await getPdf();
    pdfPreviewUrl.value = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
  } catch (error) {
    console.error('PDF preview error:', error);
  }
}
const handleKeyDown = (event: any) => {
  // Check for Ctrl+S
  if (event.ctrlKey && event.key === 'Enter') {
    event.preventDefault();
    rpmWrite();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
//날짜,기관 변경 감지 함수 시작
const mainStartDate = ref('');
const mainEndDate = ref('');
const mainCompBizGubun = ref();
const mainComp = ref('');

const changeMainComp = computed(() => {
  const mainCompComputed = store.state.mainComp
  return mainCompComputed;
});

watch(changeMainComp, async (newValue: any) => {
  if (newValue) {
    mainComp.value = newValue;
  }
});

const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun
  return bizGubunComputed;
});

watch(changeBizGubun, async (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;
  }
});

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    mainStartDate.value = newValue;
  }
});
const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    mainEndDate.value = newValue;
  }
});

//날짜,기관 변경 감지 함수 끝


//멘트관련
function formatYear(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  return `${year}`;
}
function formatMonth(inputDate: any) {
  const date = new Date(inputDate);
  const month = String(date.getMonth() + 1).padStart(2, '0');  // 패딩은 '0'으로 추가
  return `${month}`;
}
function formatyyyymm(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
}
function addComma(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return "-"; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
//제목 변수
const defaultTitle = ref('');
const rpmTitleInfo = ref(defaultTitle.value);
//내용 변수
const laborCalc = ref('');
const onmountLaborCalc = ref('');
const rpmInfo = ref(laborCalc.value);
const getMent = async () => {
  const fna = FnARptInfoArr.value
  let bungi = '';
  const s = parseInt(formatMonth(mainStartDate.value), 10);  // 문자열을 숫자로 변환
  const e = parseInt(formatMonth(mainEndDate.value), 10);  // 문자열을 숫자로 변환

  if (s === 1 && e === 3) {
    bungi = '1분기 ';
  } else if (s === 4 && e === 6) {
    bungi = '2분기 ';
  } else if (s === 7 && e === 9) {
    bungi = '3분기 ';
  } else if (s === 10 && e === 12) {
    bungi = '4분기 ';
  } else if (s === 1 && e === 12) {
    bungi = '';
  } else {
    if (s === e) {
      bungi = s + '월 ';
    } else {
      bungi = s + '~' + e + '월 ';
    }
  }

  const resultLaborPercent = parseFloat((fna.labor_out / fna.labor_in * 100).toFixed(2));
  let laborOutMinimumNotMinus = Math.floor(fna.labor_out - (fna.laborYearPercent * fna.labor_in) / 100) * -1;
  defaultTitle.value = `${formatYear(mainStartDate.value)}년 ${bungi}재무회계 보고서`;

  laborCalc.value = `1. ${bungi}인건비 지출 비율은 ${(resultLaborPercent)}%로, ${fna.laborYearPercent < resultLaborPercent ?
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족합니다.` :
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족하지 않습니다.\n * ${(fna.laborYearPercent - resultLaborPercent).toFixed(2)}% 가 부족합니다. ${addComma(laborOutMinimumNotMinus)} 원의 인건비 지출이 필요합니다.`}`
  onmountLaborCalc.value = `${bungi}인건비 지출 비율은 ${(resultLaborPercent)}%로, ${fna.laborYearPercent < resultLaborPercent ?
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족합니다.` :
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족하지 않습니다.\n * ${(fna.laborYearPercent - resultLaborPercent).toFixed(2)}% 가 부족합니다. ${addComma(laborOutMinimumNotMinus)} 원의 인건비 지출이 필요합니다.`}`
}
const rpmSelect = async () => {
  try {
    const response = await axios.get(`/api/rpmSelect/${mainComp.value}/${mainCompBizGubun.value}/${formatyyyymm(mainStartDate.value)}/${formatyyyymm(mainEndDate.value)}`);
    const responseData = response.data;
    if (responseData.rpm_memo) {
      rpmInfo.value = responseData.rpm_memo;
    } else {
      rpmInfo.value = laborCalc.value;
      console.log('작성 멘트가 존재하지 않거나 에러가 발생했습니다.');
    }
    if (responseData) {
      rpmTitleInfo.value = responseData.rpm_title;
    } else {
      rpmTitleInfo.value = defaultTitle.value;
      console.log('작성 제목이 존재하지 않거나 에러가 발생했습니다.');
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
const rpmWrite = async () => {
  try {
    if(!rpmTitleInfo.value || !rpmInfo.value){
      alert('제목과 내용을 작성해주세요.');
      return;
    }

    const encodedRpmInfo = encodeURIComponent(rpmInfo.value);
    const encodedRpmTitleInfo = encodeURIComponent(rpmTitleInfo.value);
  
    const response = await axios.post(`/api/rpmWrite/${mainComp.value}/${mainCompBizGubun.value}/${formatyyyymm(mainStartDate.value)}/${formatyyyymm(mainEndDate.value)}/${encodedRpmInfo}/${encodedRpmTitleInfo}`);

    const responseData = response.data;
    if (responseData.success === true) {
      await rpmSelect();
      previewPdf();
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};


//멘트관련 끝

const FnARptInfoArr = ref([] as any);
const FnARptInfo = async () => {
  try {
    const response = await axios.get(`/api/FnARptInfo/${mainComp.value}/${mainCompBizGubun.value}/${mainStartDate.value}/${mainEndDate.value}`);
    const responseData = response.data;

    if (responseData) {
      FnARptInfoArr.value = responseData
      await getMent();
      await rpmSelect();
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

//PDF 로직 시작////////////////////////////////////////////////////////////////////////////////////////////
const getPdf = async () => {
  try {
    await FnARptInfo();
    let encodedRpmInfo = encodeURIComponent(rpmInfo.value);
    let encodedRpmTitleInfo = encodeURIComponent(rpmTitleInfo.value);

    console.log(encodedRpmInfo)
    console.log(encodedRpmTitleInfo)
    const response = await axios.post(`/api/getPdf/${mainComp.value}/${mainCompBizGubun.value}/${mainStartDate.value}/${mainEndDate.value}/${encodedRpmInfo}/${encodedRpmTitleInfo}`, {
      fna: FnARptInfoArr.value
    }, {
      responseType: 'blob'
    });
    // 다음 단계에서 사용할 수 있도록 response.data를 반환합니다.
    return response.data;
  } catch (error) {
    console.error('PDF 파일 가져오기 중 오류 발생:', error);
    throw error;
  }
};


//PDF 로직 끝////////////////////////////////////////////////////////////////////////////////////////////


onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  await previewPdf();
})


</script>
