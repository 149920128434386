import { createApp } from 'vue'
import App from './App.vue'
import { vuetify } from './plugins'
import router from './router'
import store from './store';
import './assets/styles/main.scss'; // 스타일 파일을 import

createApp(App)  
.use(router)
.use(store) 
.use(vuetify)
.mount('#app')
