<template>
  <v-form @submit.prevent="submitForm"  class="mx-auto d-flex fontNotoSans700" style="width:90%;">
    <v-btn type="submit" :disabled="loading" style="width:185px;">크롤링 시작</v-btn>
  </v-form>

  <div v-if="crawlDureItem.length === 0 && !loading" class="fontNotoSans700" style="width: 100%; height: 300px; text-align: center; display: flex; align-items: center; justify-content: center;">자료가 없습니다.</div>
  <div v-if="loading" class="spinner tac"></div>

 <v-card v-if="crawlDureItem.length !== 0 && !loading" class="mx-auto d-flex align-center justify-center" elevation="3" style="width:90%;">
    <v-row>
      <v-col>
        <div class="d-flex align-center justify-center" style="border-bottom: 2px solid #e9eaec; height: 100px;">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col>
              <v-responsive max-width="350">
                <v-text-field v-model="search" label="검색" flat hide-details rounded="lg"
                  variant="solo-filled"></v-text-field>
              </v-responsive>
            </v-col>
            <v-col cols="9">

            </v-col>

          </v-row>
        </div>
        <div>
          <v-data-table :headers="headers" :items="filteredItems" item-value="name" :search="search" class="fontNotoSans700">
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="tac" style="max-width:50px;">{{ index + 1 }}</td>
                <td class="tal" style="min-width:120px; max-width:200px;"><v-img :src="`http://www.xn--hu1bo4e.com/`+item.imageSrc || ''"></v-img></td>
                <td class="tal" style="max-width:130px;">{{ item.brand || '' }}</td>
                <td class="tal" style="max-width:300px;">{{ item.item || '' }}</td>
                <td class="tac" style="max-width:100px;">{{ item.jaego || '' }}</td>
                <td class="tac" style="max-width:110px;">{{ item.cate_big || '' }}</td>
                <td class="tac" style="max-width:150px;">{{ item.cate_small || '' }}</td>
                <td class="tar" style="max-width:110px;">{{ addComma(item.cell) || 0 }} 원</td>
                <td class="tar" style="max-width:110px;">{{ addComma(item.buy) || 0 }} 원</td>
                <td class="tac" style="max-width:110px;">{{ item.tax || '' }}</td>
                <td class="tac" style="max-width:100px;">{{ item.isuse || '' }}</td>
            </tr>
          </template>
        </v-data-table>
        </div>
        <table>
    </table>
      </v-col>
    </v-row>
  </v-card>
</template>


<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
const loading = ref(false);
import { useStore } from 'vuex';
const store = useStore();
const crawlDureItem = ref([] as any[]);
const search = ref(''); // 검색어를 저장할 ref 변수 추가
const headers = ref([
  { title: 'No.', key: 'index',  align: 'center' },
  { title: '이미지', key: 'imageSrc', align: 'center', sortable: false },
  { title: '업 체', key: 'brand', align: 'start', sortable: true },
  { title: '상 품', key: 'item', align: 'center', sortable: true },
  { title: '재 고', key: 'jaego', align: 'center', sortable: true },
  { title: '대분류', key: 'cate_big', align: 'center', sortable: true },
  { title: '소분류', key: 'cate_small', align: 'center', sortable: true },
  { title: '판매가', key: 'cell', align: 'center', sortable: true },
  { title: '매입가', key: 'buy', align: 'center', sortable: true },
  { title: '과 세', key: 'tax', align: 'center', sortable: true },
  { title: '사 용', key: 'isuse', align: 'center', sortable: true },
] as any[]);

function addComma(number:any) {
    if (isNaN(number)) return number; // 숫자가 아닌 경우 그대로 반환
    return number.toLocaleString(); // 숫자인 경우 컴마를 추가한 문자열 반환
}

onMounted(async () => {
  crawlDureItem.value = store.state.crawlDureItem;
console.log(crawlDureItem.value);
});

const submitForm = async (): Promise<void> => {
  try {
    loading.value = true;
    const response = await axios.post("/api/crawl/dure_item");
    const crawlResponse = response.data.data as any[];
    crawlDureItem.value = crawlResponse.map(crawl => ({ ...crawl }));
    store.commit('setCrawlDureItem', crawlDureItem.value);    
    console.log('크롤링이 성공적으로 마무리되었습니다.')        
  } catch (error) {
    console.error('크롤링이 실패하였습니다. :', error);
  } finally {
    loading.value = false;
  }
};

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return crawlDureItem.value;
  }
  return crawlDureItem.value.filter(item =>
    (item.brand && item.brand.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.item && item.item.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.cate_big && item.cate_big.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.cate_small && item.cate_small.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.tax && item.tax.toLowerCase().includes(search.value.toLowerCase())) ||
    (item.isuse && item.isuse.toLowerCase().includes(search.value.toLowerCase()))
  );
});



</script>

<style scoped>
.spinner {
  border: 30px solid rgba(0, 0, 0, 0.1);
  border-left: 30px solid #8b2197;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 1s linear infinite;
  margin: 60px auto;
  text-align: center;
  display: flex; 
  align-items: center; 
  justify-content: center;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>