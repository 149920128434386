<template>
<v-row id="AdminPageComponentRow">
  <v-col xl="3" lg="3" md="4"  sm="6" xs="12" cols="3">
    <v-card  class="adminComponent align-center justify-center" elevation="3">
      <MemberTop1/>
    </v-card>
  </v-col>
  <v-col xl="3" lg="3" md="4"  sm="6" xs="12" cols="3">
    <v-card  class="adminComponent align-center justify-center" elevation="3">
      <MemberTop2/>
    </v-card>
  </v-col>
  <v-col xl="3" lg="3" md="4"  sm="6" xs="12" cols="3">
    <v-card  class="adminComponent align-center justify-center" elevation="3">
      <MemberTop3/>
  </v-card>
  </v-col>
  <v-col xl="3" lg="3" md="4"  sm="6" xs="12" cols="3">
    <v-card  class="adminComponent align-center justify-center" elevation="3">
      <MemberTop4/>
    </v-card>
  </v-col>
</v-row>
 <v-card class="mx-auto d-flex align-center justify-center" elevation="3">
    <v-row>
      <v-col>
        <div class="d-flex align-center justify-center" style="border-bottom: 2px solid #e9eaec; height: 100px;">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col>
              <v-responsive max-width="350">
                <v-text-field v-model="search" label="시설검색" flat hide-details rounded="lg"
                  variant="solo-filled"></v-text-field>
              </v-responsive>
            </v-col>
            <v-col cols="9">

            </v-col>

          </v-row>
        </div>
        <div>
          <v-data-table :headers="headers" :items="filteredDesserts" item-value="name" :search="search">
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td @click="GetMemberInfoDetail(item)" style="cursor: pointer;">

                  <div>
                    <p>{{ item.mb_name }}</p>
                    <p>{{ item.mb_id }}</p>
                  </div>
                </td>
                <td>{{ item.mb_email }}</td>
                <td>{{ item.mb_phone }}</td>
                <td>{{ item.mng_id }}</td>
                <td>{{ item.mb_auth }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import MemberTop1 from '../../components/member/MemberTop1.vue';
import MemberTop2 from '../../components/member/MemberTop2.vue';
import MemberTop3 from '../../components/member/MemberTop3.vue';
import MemberTop4 from '../../components/member/MemberTop4.vue';

const GetMemberInfoDetail = (item: any) => {
  store.dispatch('SetMemberInfoDetail', item);
  router.push('/adminmain/MemberInfoDetail');
}

const desserts = ref([] as any[]); // desserts를 ref로 만들어 반응성 부여

const getMemberInfo = async () => {
  try {
    const response = await axios.get("/api/getMemberInfo");
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      desserts.value = responseData;
      console.log(desserts.value,'desserts.value')
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

const headers = ref([
  { title: '순번', key: 'index' },
  { title: '시설/ID', key: 'mb_name', align: 'start', sortable: true },
  { title: '이메일', key: 'mb_email', align: 'start', sortable: true },
  { title: '번호', key: 'mb_phone', align: 'start', sortable: true },
  { title: '담당자', key: 'mng_id', align: 'start', sortable: true },
  { title: '인증', key: 'mb_auth', align: 'start', sortable: true },
] as any[]);

const search = ref(''); // 검색어를 저장할 ref 변수 추가

const filteredDesserts = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return desserts.value;
  }
  return desserts.value.filter(item =>
    item.mb_name.toLowerCase().includes(search.value.toLowerCase()) ||
    item.mb_id.toLowerCase().includes(search.value.toLowerCase()) ||
    item.mb_email.toLowerCase().includes(search.value.toLowerCase()) ||
    item.mb_phone.toLowerCase().includes(search.value.toLowerCase()) ||
    item.mng_id.toLowerCase().includes(search.value.toLowerCase()) ||
    item.mb_auth.toLowerCase().includes(search.value.toLowerCase())
  );
});

onMounted(() => {
  getMemberInfo();
})

</script>