<template>
  <v-app >
      <slot />

    <v-footer
      app
      height="72"
    >
    </v-footer>
  </v-app>
</template>