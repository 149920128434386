<template>
  <v-dialog persistent v-model="dialog" max-width="800" >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn color="red-lighten-2" style="font-size: 14px;" v-bind="activatorProps">※설명</v-btn>
    </template>
    <v-card class="fontNotoSans400">
      <v-card-title class="inoutCtrlTitle pr-6 pl-6 pt-4">
        <b>사용법</b>
        <v-icon class="closeIcon mt-1" @click="dialog = false" size="small">{{ mdiWindowClose }}</v-icon>
      </v-card-title>
      <v-card-text class="pb-8">
        <h4>1. 최상단 시설, 사업구분 선택 후 양식 작성 후 [엑셀업로드 파일선택] 으로 파일 업로드 (1개월 단위)</h4>
        <p style="font-size: 13px;"> - 엑셀업로드는 1개월 단위로 인식합니다. ex)2024-07-01 ~ 2024-08-31 엑셀업로드 불가</p>
        <p style="font-size: 13px;"> - 관항목, 자금원천, 상대계정은 과거데이터 토대로 자동삽입</p>
        <p style="font-size: 13px;"> - 생계비↔식재료비 / 생계비보조↔시군구보조 / 의료비↔기타비급여 매칭.</p>
        <p style="font-size: 13px;"> - 엑셀양식의 날짜는 2024-09-12 형식으로 작성.</p>
        
        <h4 class="mt-2">2. 표의 각 가로열 우측에서 개별 대상에 대한 [조정],[삭제] 기능 사용 가능.</h4>
        <h4 class="mt-2">3. 표의 좌측 세로열에서 기능적용 대상 선택 후 표의 우측 최상단의 [선택조정], [선택삭제] 기능 사용 가능.</h4>
        <p style="font-size: 13px;"> - 각 항목마다 (조정하기) 클릭 후 작성.</p>
        <p style="font-size: 13px;"> - (X)는 수정 불가 항목.</p>
        <p style="font-size: 13px;"> - 좌상단 [구분] 조건에 맞는 항목의 데이터가 출력.</p>
        <h4 class="mt-2">4. 표의 좌측 상단에서 조건검색 가능.</h4>
        <h4 class="mt-2">5. 기존의 [재무회계 보고서] 및 [W4C임금총액] 은 우상단 [보고서] 로 진입 가능.</h4>
        <h4 class="mt-2">6. [보고서] → [현금출납누적현황표] 는 관항목 기준으로 현금출납 내역 확인 가능, 날짜 조건 검색 가능.</h4>
      </v-card-text>
       <v-card-title class="inoutCtrlTitle pr-6 pl-6 pt-4">
        <b>개발자코멘트</b>
      </v-card-title>
      <v-card-text class="pb-8">
        <p style="font-size: 13px;">※ 엑셀 업로드 시 해당월의 기존 데이터는 모두 사라집니다.</p>
        <p style="font-size: 13px;">- 엑셀 업로드 시 관항목, 상대계정의 자동조정은 잔여금액에 따라서 자동부여되는것이 아닌, 과거 계정조정 통계를 근거로 자동부여됩니다.</p>
        <p style="font-size: 13px;">- 후원금에 대한 조정기능이 아직 없습니다.</p>
        <p style="font-size: 13px;">- 기능 테스트 후 개선사항 및 기능추가사항 등 피드백이 필요합니다.</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { mdiWindowClose } from '@mdi/js';
import { ref } from 'vue';

const dialog = ref(false);

</script>