<template>
<v-dialog v-model="dialog" max-width="1000">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps" @click="getInoutDetailCalc()" class="jogunSelect">
          현금출납누적현황표
        </v-btn>
      </template>

      <v-card class="fontNotoSans400" style="min-height: 500px;">
        <v-card-title class="mt-4 ml-3">
          <h3>현금출납누적현황표</h3>
        </v-card-title>

        <v-card-text v-if="isLoading" >
          <div class="loading-spinner"></div>
        </v-card-text>
        <v-card-text v-else>
          <div style="display: flex; align-items: center; padding: 6px 6px 12px 0; border-bottom: 2px solid #d1d1d1;">
            <p>구분 선택</p>
            <select
              v-model="selectedInout"
              @change="dynamicFilter"
              label=""
              class="jogunSelect"
            >
              <option value="all" label="구분 (전체)"></option>
              <option value="수입" label="수입"></option>
              <option value="지출" label="지출"></option>
            </select>
            <v-spacer></v-spacer>
              <input type="month" color="indigo" v-model="startDate" class="mr-3" min="2000-01" max="2099-12" /> ~
              <input type="month" color="indigo" v-model="endDate" class="ml-3" min="2000-01" max="2099-12" />
              <v-btn @click="getInoutDetailCalc()" class="jogunSelect">
                검색
              </v-btn>

            <v-spacer></v-spacer>
            <div class="fontNotoSans400" style="display: flex; align-items: center; justify-content: center;">
              <table>
                <tbody>
                  <tr>
                    <td class="pr-2" style="border-right: 1px solid #d1d1d1;" rowspan="3">합산</td>
                    <td class="pl-2">수입</td>
                    <td class="tar">{{addComma(total_in)}}</td>
                  </tr>
                  <tr>
                    <td class="pl-2 pr-2">지출</td>
                    <td class="tar">{{addComma(total_out)}}</td>
                  </tr>
                  <tr>
                    <td class="pl-2 pr-2">차액</td>
                    <td class="tar">{{addComma(total_in - total_out)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <v-tabs -->
            <!-- grow -->
          <!-- > -->
            <!-- <v-tab @click="tab = 1"></v-tab> -->
            <!-- <v-tab @click="tab = 2">전체 x관항목</v-tab> -->
          <!-- </v-tabs> -->

            <template v-if="tab === 1">
              <v-data-table
                fixed-header
                density="compact"
                scrollable
                :headers="headersArr3"
                :items="inoutList3"
                item-value="name"
                id="inoutDetailTb"
                :items-per-page="-1"
                :items-per-page-options="[
                  // { value: 50, title: '50' },
                  // { value: 100, title: '100' },
                  // { value: 200, title: '200' },
                  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
                ]"
              >
                <template v-slot:item="{ item , index}">
                  <tr>
                    <td class="tac" style="color: #000; opacity: 1;">{{ index + 1 }}</td>
                    <td class="tac" style="color: #000; opacity: 1;">{{ item.sname || '' }}</td>
                    <td class="tar" style="color: #000; opacity: 1;">{{ addComma(item.total_in) || '' }}</td>
                    <td class="tar" style="color: #000; opacity: 1;">{{ addComma(item.total_out) || '' }}</td>
                    <td class="tar" style="color: #000; opacity: 1;">{{ addComma(item.difference) || '' }}</td>
                  </tr>
                </template>
              </v-data-table>
            </template>
        </v-card-text>
          <v-btn style="height: 40px;" @click="dialog = false" size="big">
            닫기
          </v-btn>
      </v-card>
    </v-dialog>
<table>

</table>
</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';
const tab = ref(1);
const dialog = ref(false);
const inoutList = ref([] as any[]);
const inoutList2 = ref([] as any[]);
const inoutList3 = ref([] as any[]);

const isLoading = ref(false);
function formatYear(inputDate:any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  return `${year}`;
}
let startDate = ref(`${formatYear(store.state.mainStartDate)}-01`);
let endDate = ref(`${formatYear(store.state.mainEndDate)}-12`);
let name = ref(store.state.mainComp);
let bizGubun = ref(store.state.mainCompBizGubun);


const headersArr3 = ref([
  { title: "No.", key: "index", sortable: false },
  { title: "관항목", key: "sname", align: "center", sortable: true },
  { title: "수입", key: "total_in", align: "end", sortable: true },
  { title: "지출", key: "total_out", align: "end", sortable: true },
  { title: "차액", key: "difference", align: "end", sortable: true },
] as any[]);


const selectedInout = ref("all");

const dynamicFilter = () => {
  let filteredData = inoutList2.value;
  const filters: Record<string, string> = {
    gubun: selectedInout.value,
  };

  // filters 객체를 순회하면서 필터를 적용
  for (const key in filters) {
    const value = filters[key];
    if (value !== "all") {
      filteredData = filteredData.filter((item) => {
        return item[key] === value;
      });
    }
  }
  inoutList.value = filteredData;
};
const total_in = ref(0);
const total_out = ref(0);

const getInoutDetailCalc = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/getInoutDetailCalc/${(startDate.value)}/${(endDate.value)}/${name.value}/${bizGubun.value}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      inoutList3.value = responseData;
      // total_in과 total_out의 합 계산

      total_in.value = responseData.reduce((sum, item) => {
        return sum + (item.total_in || 0); // total_in이 없으면 0으로 처리
      }, 0);

      total_out.value = responseData.reduce((sum, item) => {
        return sum + (item.total_out || 0); // total_out이 없으면 0으로 처리
      }, 0);


      store.commit('setInoutDetailList', responseData);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    isLoading.value = false;
  }
};

function addComma(number:any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return "0"; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}

</script>
