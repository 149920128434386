<template>
  <v-navigation-drawer 
  location="left" 
  v-model="store.state.drawer" 
  id="MenuNavi"
  disable-resize-watcher 
  >

    <v-list density="compact" nav>
      <v-list-item @click="indexPage" class="tac">
        <img src="@/assets/pictures/icon.png" style="width:45%; height: 100%; cursor: pointer;" />
      </v-list-item>
      <v-menu v-for="(section, index) in sections" :key="index">
        <template v-slot:activator="{ props }">
          <v-list-item style="height:42px; padding: 0 25px 2px 20px !important;"
            @click="toggleDrawer"
            v-bind="props"
            :to="`/${section.link.toLowerCase()}`"
            :class="{ 'v-btn--active': isActive(section.link) }"
            class="activeClick">
            <v-icon class="menuNaviIcon" style="margin-bottom: 3px;">{{ section.prependIcon }}</v-icon>
            {{ section.title }}
          </v-list-item>
        </template>
      </v-menu>
    </v-list>
    <template v-slot:append>
    </template>
  </v-navigation-drawer>
</template>


<script setup lang="ts">
import router from '@/router';
import {
  //  mdiFoodApple,
  mdiAccountMultiple,
  mdiCurrencyKrw  

} from '@mdi/js'
import { useStore } from 'vuex';
const store = useStore();

const toggleDrawer = () => {
  store.commit('setDrawer', !store.state.drawer);
  console.log(store.state.drawer)
};
const indexPage = () => {
  store.commit('setDrawer', !store.state.drawer);
  router.push('/indexPage');
}
const sections = [
  { title: '롱텀케어', link: 'CrawlingPage', prependIcon: mdiAccountMultiple },
  { title: '현금출납부', link: 'MngInout',prependIcon: mdiCurrencyKrw  },
  { title: 'w4c 매크로', link: 'TestOne' },
  { title: '회원관리', link: 'AdminMain' },
  { title: '게시판관리', link: 'TestTwo' },
  { title: '기타설정', link: 'EtcConfig' },
];
const isActive = (link: any) => {
  const currentPath = router.currentRoute.value.path.toLowerCase();
  const includes = currentPath.includes(`/${link.toLowerCase()}`);
  // console.log(`Checking ${link}. Current Path: ${currentPath}. Result: ${includes}`);
  return includes;
};
</script>