<template>
  <v-btn @click="deleteInoutData" color="blue-grey-lighten-5" class="inoutExcelUsingBtn">삭제</v-btn>
</template>

<script setup lang="ts">
import axios from 'axios';
import { defineProps } from 'vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['success']);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});
function formatYear(inputDate: any): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();

  return `${year}`;
}
const deleteInoutData = async () => {
  try {
    const idx =props.item.idx;
    console.log(props.item,'item')
    const isConfirmed = window.confirm('정말 삭제하시겠습니까?');
    if (isConfirmed) {
      const response = await axios.post(`/api/deleteInoutData/${idx}/${props.item.memid}/${props.item.biz_gubun}/${formatYear(props.item.inout_date)}`,);
      const result = response.data;
      if(result){
        alert('삭제가 완료되었습니다.');
        emit('success');
      }else{
        alert('삭제에 실패하였습니다.');
      }
    }else{
      return;
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }
};
</script>