<template v-if="isAdmin">
  <v-container>
    <v-row>
      <v-col xl="2" lg="3" md="4"  sm="12" xs="12" cols="3">
          <v-card>
            <MemberInfoLeftCard />
          </v-card>
      </v-col>
      <v-col xl="10" lg="9" md="8"  sm="12" xs="12" cols="3">
            <MemberInfoRightCard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import store from '../../store';
import MemberInfoLeftCard from '../../components/member/MemberInfoLeftCard.vue'
import MemberInfoRightCard from '../../components/member/MemberInfoRightCard.vue'

const router = useRouter();
const isAdmin = computed(() => store.getters.isAdmin);

router.beforeEach((to: any, from: any, next: any) => {
  if (to.name === 'AdminMain' && !isAdmin.value) {
    alert('관리자 권한이 필요합니다.');
    next({ name: 'MemberInfoDetail' });
  } else {
    next();
  }
});

</script>
