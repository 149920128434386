<template>
  <div>
    <v-btn :prepend-icon="mdiCog" text="재무회계 보고서" @click="openRptNewPage" class="jogunSelect"></v-btn>
    <v-btn @click="downloadPdf" class="jogunSelect" style="display: none;">PDF 다운로드</v-btn>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';
import store from '@/store';
import {
  mdiCog,
} from '@mdi/js'
//날짜,기관 변경 감지 함수 시작
const mainStartDate = ref('');
const mainEndDate = ref('');
const mainCompBizGubun = ref();
const mainComp = ref('');

const changeMainComp = computed(() => {
  const mainCompComputed = store.state.mainComp
  return mainCompComputed;
});

watch(changeMainComp, async (newValue: any) => {
  if (newValue) {
    mainComp.value = newValue;
  }
});

const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun
  return bizGubunComputed;
});

watch(changeBizGubun, async (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;
  }
});

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    mainStartDate.value = newValue;
  }
});
const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    mainEndDate.value = newValue;
  }
});

//날짜,기관 변경 감지 함수 끝


//멘트관련
function formatYear(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  return `${year}`;
}
function formatMonth(inputDate: any) {
  const date = new Date(inputDate);
  const month = String(date.getMonth() + 1).padStart(2, '0');  // 패딩은 '0'으로 추가
  return `${month}`;
}
function formatyyyymm(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
}
function addComma(number: any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return "-"; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
const laborCalc = ref('');
let bungi = '';

const rpmInfo = ref(laborCalc.value);
const getMent = async () => {
  const fna = FnARptInfoArr.value
  const s = parseInt(formatMonth(mainStartDate.value), 10);  // 문자열을 숫자로 변환
  const e = parseInt(formatMonth(mainEndDate.value), 10);  // 문자열을 숫자로 변환

  if (s === 1 && e === 3) {
    bungi = '1분기 ';
  } else if (s === 4 && e === 6) {
    bungi = '2분기 ';
  } else if (s === 7 && e === 9) {
    bungi = '3분기 ';
  } else if (s === 10 && e === 12) {
    bungi = '4분기 ';
  } else if (s === 1 && e === 12) {
    bungi = '';
  } else {
    if (s === e) {
      bungi = s + '월 ';
    } else {
      bungi = s + '~' + e + '월 ';
    }
  }
  const resultLaborPercent = parseFloat((fna.labor_out / fna.labor_in * 100).toFixed(2));
  let laborOutMinimumNotMinus = Math.floor(fna.labor_out - (fna.laborYearPercent * fna.labor_in) / 100) * -1;
  laborCalc.value = `1. ${bungi}인건비 지출 비율은 ${(resultLaborPercent)}%로, ${fna.laborYearPercent < resultLaborPercent ?
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족합니다.` :
    `${formatYear(mainStartDate.value)}년 인건비 지출 기준을 충족하지 않습니다.\n * ${(fna.laborYearPercent - resultLaborPercent)}% 가 부족합니다. ${addComma(laborOutMinimumNotMinus)} 원의 인건비 지출이 필요합니다.`}`
}

const rpmSelect = async () => {
  try {
    const response = await axios.get(`/api/rpmSelect/${mainComp.value}/${mainCompBizGubun.value}/${formatyyyymm(mainStartDate.value)}/${formatyyyymm(mainEndDate.value)}`);
    const responseData = response.data;
    if (responseData) {
      rpmInfo.value = response.data.rpm_memo
      return responseData; // 가져온 데이터를 반환
    } else {
      rpmInfo.value = laborCalc.value;
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

//멘트관련 끝

const FnARptInfoArr = ref([] as any);
const FnARptInfo = async () => {
  try {
    const response = await axios.get(`/api/FnARptInfo/${mainComp.value}/${mainCompBizGubun.value}/${mainStartDate.value}/${mainEndDate.value}`);
    const responseData = response.data;

    if (responseData) {
      FnARptInfoArr.value = responseData
      await getMent();
      await rpmSelect();
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

//PDF 로직 시작////////////////////////////////////////////////////////////////////////////////////////////
  const wageInputs = ref([]);
const getPdf = async () => {
  try {
    await getWages();
    if(wageInputs.value.length === 0){
      alert('W4C 임금총액 입력,저장이 필요합니다.');
      return;
    }else{
    await FnARptInfo();
    const encodedRpmInfo = encodeURIComponent(rpmInfo.value);

    const response = await axios.post(`/api/getPdf/${mainComp.value}/${mainCompBizGubun.value}/${mainStartDate.value}/${mainEndDate.value}/${encodedRpmInfo}`, {
      fna: FnARptInfoArr.value
    }, {
      responseType: 'blob'
    });
    // 다음 단계에서 사용할 수 있도록 response.data를 반환합니다.
    return response.data;
  }
  } catch (error) {
    console.error('PDF 파일 가져오기 중 오류 발생:', error);
    throw error;
  }
};


const downloadPdf = async () => {
  try {
    
    const pdfData = await getPdf();
    if(wageInputs.value.length === 0){
      return;
    }
    const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', `${formatYear(mainStartDate.value)}년 ${bungi}재무회계보고서_${mainComp.value}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('PDF 다운로드 중 오류 발생:', error);
  }
};


//PDF 로직 끝////////////////////////////////////////////////////////////////////////////////////////////
const getWages = async () => {
  try {
    const response = await axios.get(`/api/getWages/${mainComp.value}/${mainCompBizGubun.value}/${formatYear(mainStartDate.value)}`);
    const responseData = response.data;
    if (responseData) {
      wageInputs.value = responseData
      console.log(wageInputs.value,'wageInputs.value')
      return responseData; // 가져온 데이터를 반환
    } else{
      wageInputs.value = [];
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};




let newPageRef: Window | null = null; // 새 창의 참조 변수

const openRptNewPage = async () => {
  await getWages();
  if(wageInputs.value.length === 0){
    alert('W4C 임금총액 입력,저장이 필요합니다.');
      return;
    }
  if (newPageRef && !newPageRef.closed) { // 이미 열린 창이 있고 닫히지 않았을 때
    newPageRef.location.reload(); // 창 다시 로드
  } else {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.round(screenWidth * 0.5);
    const height = Math.round(screenHeight * 1);
    const left = (screenWidth - width) / 1;
    const top = (screenHeight - height) / 2;
    const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, left=${left}, top=${top}`;
    
    newPageRef = window.open("#/MngRptNewPage",`${formatYear(mainStartDate.value)}년 ${bungi}재무회계보고서_${mainComp.value}.pdf`, options);
  }
};


onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
})


</script>
