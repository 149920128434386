<template>
  <v-card style="min-height: 80vh">
    <div style="display: flex; align-items: center; padding: 6px 6px 6px 0">
      <h3 style="margin: 0 20px 0 10px">💰현금출납부</h3>
      <template v-if="inoutList.length !== 0 && !isLoading">
        <select
          v-model="selectedInout"
          @change="dynamicFilter"
          label=""
          class="jogunSelect"
        >
          <option value="all" label="구분 (전체)"></option>
          <option value="수입" label="수입"></option>
          <option value="지출" label="지출"></option>
        </select>
        <v-text-field
          class="inoutSearchInput"
          style="max-width: 200px; width: auto; min-width: 70px; margin: 0 20px 0 20px"
          v-model="search"
          color="#0f7545"
          label="적요검색"
          single-line
          clearable
          :append-inner-icon="mdiMagnify"
          flat
          hide-details="auto"
          variant="outlined"
          density="compact"
        >
        </v-text-field>
      </template>
      <p>선택금액 총: {{ addComma(totalPrice) }} 원</p>

      <v-spacer></v-spacer>
      <HowDid />
      <v-btn @click="downloadExcelFile('현금출납부_업로드양식.xlsx')" class="jogunSelect">
        엑셀 양식
      </v-btn>
      <v-btn @click="onClickSelectFile" v-if="inoutStatus !== '출력'" class="jogunSelect">
        엑셀업로드 파일선택
      </v-btn>
      <input
        type="file"
        accept=".xlsx, .xls"
        ref="fileInput"
        @change="onFileChange"
        style="display: none"
      />

      <span v-if="selectedFileName && inoutStatus !== '출력'" class="ml-4">
        {{ selectedFileName }}
      </span>

      <v-btn
        class="ml-4 jogunSelect"
        @click="onClickUpload"
        v-if="selectedFile && inoutStatus !== '출력'"
      >
        업로드시작
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ props }">
          <v-btn class="jogunSelect" v-bind="props">보고서</v-btn>
        </template>
        <v-list>
          <v-list-item>
            <TestMakePdf></TestMakePdf>
          </v-list-item>
          <v-list-item>
            <W4cTotalPay></W4cTotalPay>
          </v-list-item>
          <v-list-item>
            <InoutDetail />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-data-table
      fixed-header
      density="compact"
      scrollable
      :headers="headersArr"
      :items="filteredItems"
      :search="search"
      item-value="name"
      class="itemListTable fontNotoSans400"
      style="
        max-height: calc(100vh - 148px);
        height: calc(100vh - 148px);
        overflow-y: hidden;
        position: relative;
      "
      :items-per-page="50"
      :items-per-page-options="[
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: 200, title: '200' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ]"
      v-if="inoutList.length !== 0 && !isLoading"
    >
      <template v-slot:item="{ item, index }">
        <tr class="displayNoneTr">
          <td
            colspan="1"
            class="tal"
            style="display: flex; justify-content: left; align-items: center"
          >
            <v-checkbox
              style="display: inline-block"
              :model-value="isAllSelected"
              @click="toggleAllSelect"
              color="black"
              base-color="black"
              hide-details
            >
            </v-checkbox>
            <span>All</span>
          </td>
          <td class="tac">
            <v-btn
              v-if="!gubunTo"
              @click="toggleUpdate('gubunTo')"
              :append-icon="mdiCached"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
              <span>조정하기</span>
            </v-btn>
            <select v-if="gubunTo" v-model="allGubun">
              <option
                v-for="option in gubunValue"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>

            <v-icon v-if="gubunTo" @click="toggleUpdate('gubunTo')">{{
              mdiCached
            }}</v-icon>
          </td>
          <td class="tac">
            <v-btn
              @click="toggleUpdate('X')"
              text="X"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
            </v-btn>
          </td>
          <td class="tac">
            <v-btn
              v-if="!memoTo"
              @click="toggleUpdate('memoTo')"
              :append-icon="mdiCached"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
              <span>조정하기</span>
            </v-btn>
            <input class="inoutAllInput" v-if="memoTo" type="text" v-model="allMemo" />
            <v-icon v-if="memoTo" @click="toggleUpdate('memoTo')">{{ mdiCached }}</v-icon>
          </td>
          <td class="tar">
            <v-btn
              v-if="!priceTo"
              @click="toggleUpdate('priceTo')"
              :append-icon="mdiCached"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
              <span>조정하기</span>
            </v-btn>
            <input
              class="inoutAllInput tar"
              v-if="priceTo"
              type="text"
              v-model="allPrice"
            />
            <v-icon v-if="priceTo" @click="toggleUpdate('priceTo')">{{
              mdiCached
            }}</v-icon>
          </td>
          <td class="tac" v-if="!snameTo">
            <v-btn
              @click="toggleUpdate('snameTo')"
              :append-icon="mdiCached"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
              <span>조정하기</span>
            </v-btn>
          </td>
          <td
            class="tac"
            v-else
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <v-combobox
              v-if="snameTo"
              v-model="allSname"
              :items="snameList"
              variant="underlined"
              density="compact"
              hide-details="auto"
            ></v-combobox>
            <v-icon v-if="snameTo" @click="toggleUpdate('snameTo')">{{
              mdiCached
            }}</v-icon>
          </td>
          <td class="tac">
            <v-btn
              v-if="!fundingTo"
              @click="toggleUpdate('fundingTo')"
              :append-icon="mdiCached"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
              <span>조정하기</span>
            </v-btn>
            <select v-if="fundingTo" v-model="allFunding">
              <option
                v-for="option in fundingValue"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <v-icon v-if="fundingTo" @click="toggleUpdate('fundingTo')">{{
              mdiCached
            }}</v-icon>
          </td>
          <td class="tac">
            <v-btn
              @click="toggleUpdate('X')"
              text="X"
              elevation="0"
              class="inoutAllUpdateBtn"
            >
            </v-btn>
          </td>

          <td class="tac" v-if="inoutStatus !== '출력'">
            <v-btn
              @click="updateAllInoutData()"
              class="inoutExcelUsingBtn2"
              color="indigo-lighten-2"
              >선택조정</v-btn
            >
            <v-btn
              @click="deleteAllInoutData()"
              class="inoutExcelUsingBtn2"
              color="blue-grey-lighten-5"
              >선택삭제</v-btn
            >
          </td>
          <td class="tac" v-else>조정불가</td>
        </tr>
        <tr>
          <td
            class="tal"
            style="display: flex; justify-content: left; align-items: center"
          >
            <v-checkbox
              style="display: inline-block"
              v-model="selectEmp"
              :value="item.idx"
              color="deep-purple-darken-3"
              base-color="deep-purple-lighten-1
"
              hide-details
            ></v-checkbox
            >{{ index + 1 }}.
          </td>
          <td class="tac">{{ item.gubun || "" }}</td>
          <td class="tac">{{ item.inout_date || "" }}</td>
          <td class="tac">{{ item.memo || "" }}</td>
          <td class="tar">{{ addComma(item.price) || "" }} 원</td>
          <td class="tac">{{ item.sname || "" }}</td>
          <td class="tac">{{ item.funding || "" }}</td>
          <td class="tac">{{ item.rel_acc || "" }}</td>
          <td style="min-width: 133px" class="tac" v-if="inoutStatus !== '출력'">
            <InoutExcelUpdate :item="item" @success="getInout()" />
            <InoutExcelDelete :item="item" @success="getInout()" />
          </td>
          <td v-else class="tac">출력완료</td>
        </tr>
      </template>
    </v-data-table>
    <div v-if="isLoading" class="loading-spinner"></div>
    <div
      v-else-if="inoutList.length === 0 && !isLoading"
      style="
        width: 100%;
        height: 300px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      데이터가 존재하지 않습니다.
      <br />
      상태: {{ inoutStatus }}
      <v-btn @click="onClickSelectFile" v-if="inoutStatus !== '출력'" class="jogunSelect">
        엑셀업로드 파일선택
      </v-btn>
      <input
        type="file"
        accept=".xlsx, .xls"
        ref="fileInput"
        @change="onFileChange"
        style="display: none"
      />

      <span v-if="selectedFileName && inoutStatus !== '출력'" class="ml-4">
        {{ selectedFileName }}
      </span>

      <v-btn
        class="ml-4 jogunSelect"
        @click="onClickUpload"
        v-if="selectedFile && inoutStatus !== '출력'"
      >
        업로드시작
      </v-btn>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import HowDid from "../../components/make_pdf/HowDid.vue";
import InoutDetail from "../../components/make_pdf/InoutDetail.vue";
import InoutExcelDelete from "../../components/make_pdf/InoutExcelDelete.vue";
import InoutExcelUpdate from "../../components/make_pdf/InoutExcelUpdate.vue";
// import InoutExcelUpload from "../../components/make_pdf/InoutExcelUpload.vue";
import TestMakePdf from "../../components/make_pdf/TestMakePdf.vue";
import W4cTotalPay from "../../components/make_pdf/W4cTotalPay.vue";

import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import { mdiMagnify, mdiCached } from "@mdi/js";
const snameList = ref([] as string[]); // sname만 담을 배열
const inNames = ref([] as any);
const outNames = ref([] as any);
const allNames = ref([] as string[]); // sname만 담을 배열

const allGubun = ref("");
const allPrice = ref("");
const allSname = ref("");
const allMemo = ref("");
const allFunding = ref("");
const gubunTo = ref(false);
const priceTo = ref(false);
const snameTo = ref(false);
const memoTo = ref(false);
const fundingTo = ref(false);

const toggleUpdate = (item: any) => {
  if (item === "gubunTo") {
    gubunTo.value = !gubunTo.value;
  } else if (item === "priceTo") {
    priceTo.value = !priceTo.value;
  } else if (item === "snameTo") {
    snameTo.value = !snameTo.value;
  } else if (item === "fundingTo") {
    fundingTo.value = !fundingTo.value;
  } else if (item === "memoTo") {
    memoTo.value = !memoTo.value;
  }
};
const gubunValue = [
  { value: "IN", label: "수입" },
  { value: "OUT", label: "지출" },
];
const fundingValue = [
  { value: "07", label: "보조금" },
  { value: "06", label: "수익사업" },
  { value: "05", label: "후원금" },
  { value: "04", label: "자부담" },
];

const isLoading = ref(false);
const selectedInout = ref("all");

const selectEmp = ref<string[]>([]);
const isAllSelected = computed(() => selectEmp.value.length === inoutList.value.length);

// 전체 선택/해제 함수
const toggleAllSelect = () => {
  if (isAllSelected.value) {
    selectEmp.value = []; // 전체 해제
  } else {
    selectEmp.value = inoutList.value.map((f) => f.idx); // 전체 선택
  }
};
const totalPrice = computed(() => {
  return selectEmp.value.reduce((sum, idx) => {
    const item = inoutList.value.find((f) => f.idx === idx);
    return item ? sum + item.price : sum;
  }, 0);
});
//날짜,기관 변경 감지 함수 시작
const mainStartDate = ref("");
const mainEndDate = ref("");
const mainCompBizGubun = ref();
const mainComp = ref("");

const changeMainComp = computed(() => {
  const mainCompComputed = store.state.mainComp;
  return mainCompComputed;
});

watch(changeMainComp, async (newValue: any) => {
  if (newValue) {
    mainComp.value = newValue;

    await getInout();
  }
});

const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun;
  return bizGubunComputed;
});

watch(changeBizGubun, async (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;

    await getInout();
  }
});

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate;
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    mainStartDate.value = newValue;

    await getInout();
  }
});
const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate;
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    mainEndDate.value = newValue;

    await getInout();
  }
});

const changeGubun = computed(() => {
  const gubunComputed = selectedInout.value;
  return gubunComputed;
});

watch(changeGubun, async (newValue: any) => {
  if (newValue === "수입") {
    snameList.value = inNames.value;
  } else if (newValue === "지출") {
    snameList.value = outNames.value;
  } else {
    snameList.value = allNames.value;
  }
});

//날짜,기관 변경 감지 함수 끝

function addComma(number: number) {
  // 숫자가 아니거나 null인 경우 그대로 반환
  if (number === null || isNaN(number)) return number;

  // 숫자를 문자열로 변환 후 컴마 추가
  var strNumber = number.toLocaleString("en-US", { maximumFractionDigits: 2 });

  // 소수점 이하가 모두 0이 아닌 경우 처리
  var decimalIndex = strNumber.indexOf(".");
  if (decimalIndex !== -1) {
    var decimalPart = strNumber.substr(decimalIndex + 1);
    if (decimalPart !== "" && !/^0+$/.test(decimalPart)) {
      // 소수점 이하가 모두 0이 아닌 경우, 소수점 이하를 그대로 유지
      return strNumber;
    }
  }
  // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
  return strNumber
    .split(".")[0]
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const headersArr = ref([
  { title: "No.", key: "index", sortable: false },
  { title: "구분", key: "gubun", align: "center", sortable: true },
  { title: "일자", key: "inout_date", align: "center", sortable: true },
  { title: "적요", key: "memo", align: "center", sortable: true },
  { title: "금액", key: "price", align: "center", sortable: true },
  { title: "관항목", key: "sname", align: "center", sortable: true },
  { title: "자금원천", key: "funding", align: "center", sortable: true },
  { title: "상대계정", key: "rel_acc", align: "center", sortable: true },
  { title: "기능", key: "", align: "center", sortable: true },
] as any[]);

const search = ref("");

const filteredItems = computed(() => {
  // 검색어가 비어 있으면 전체 리스트 반환
  if (!search.value) {
    return inoutList.value;
  }
  return inoutList.value.filter(
    (item) =>
      (item.memo && item.memo.toLowerCase().includes(search.value.toLowerCase())) ||
      (item.sname && item.sname.toLowerCase().includes(search.value.toLowerCase()))
  );
});

const dynamicFilter = () => {
  let filteredData = inoutList2.value;
  const filters: Record<string, string> = {
    gubun: selectedInout.value,
  };

  // filters 객체를 순회하면서 필터를 적용
  for (const key in filters) {
    const value = filters[key];
    if (value !== "all") {
      filteredData = filteredData.filter((item) => {
        return item[key] === value;
      });
    }
  }
  inoutList.value = filteredData;
};

const inoutList = ref([] as any[]);
const inoutList2 = ref([] as any[]);
const getInout = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get(
      `/api/getInout/${mainComp.value}/${mainCompBizGubun.value}/${mainStartDate.value}/${mainEndDate.value}`
    );
    const responseData = response.data;
    if (Array.isArray(responseData)) {
      inoutList.value = responseData;
      inoutList2.value = responseData;
      dynamicFilter();
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    await getInoutStatus();
    await getInoutDetailCalc();
    isLoading.value = false;
  }
};
function formatyyyymm(inputDate: any): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");

  return `${year}-${month}`;
}
const inoutStatus = ref(null);
const getInoutStatus = async () => {
  try {
    const response = await axios.get(
      `/api/getInoutStatus/${mainComp.value}/${formatyyyymm(store.state.mainStartDate)}`
    );
    const responseData = response.data;
    if (responseData) {
      inoutStatus.value = responseData;
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};

const fileInput = ref();
const selectedFile = ref(null);
const selectedFileName = ref(null);

const onClickSelectFile = () => {
  fileInput.value.click();
};

const onFileChange = (event: any) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    selectedFileName.value = file.name;

    // 리셋 파일 인풋을 통해 새 파일 선택 가능하게 함
    event.target.value = null;
  }
};

const onClickUpload = async () => {
  if (selectedFile.value) {
    const formData = new FormData();
    formData.append("file", selectedFile.value);
    const isConfirmed = window.confirm(`${selectedFileName.value}을 업로드하시겠습니까?`);
    if (isConfirmed) {
      try {
        isLoading.value = true;
        const response = await axios.post(
          `/api/uploadExcel/${store.state.mainComp}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.success) {
          selectedFile.value = null;
          selectedFileName.value = null;
          await getInout();
        }
      } catch (error) {
        console.error("파일 업로드 실패:", error);
      } finally {
        isLoading.value = false;
      }
    }
  }
};
const allComCode = ref([] as any);

const getAllComCode = async () => {
  try {
    const response = await axios.get(`/api/getAllComCode/${store.state.mainStartDate}`);
    const responseData = response.data;
    if (responseData) {
      allComCode.value = responseData;
      const inData = responseData.filter(
        (item: { trcode: string }) => item.trcode === "IN"
      );
      const outData = responseData.filter(
        (item: { trcode: string }) => item.trcode === "OUT"
      );
      inNames.value = inData.map((item: { sname: any; scode: any }) => `${item.sname}`);
      outNames.value = outData.map((item: { sname: any; scode: any }) => `${item.sname}`);
      allNames.value = allComCode.value.map((item: any) => item.sname);
      snameList.value = allNames.value;

      return responseData;
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
function formatYear(inputDate: any): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();

  return `${year}`;
}
const updateAllInoutData = async () => {
  try {
    const isConfirmed = window.confirm("정말 조정하시겠습니까?");
    if (isConfirmed) {
      isLoading.value = true;
      // form 객체 생성
      const form: any = {
        gubun: gubunTo.value ? allGubun.value : "",
        price: priceTo.value ? allPrice.value : "",
        sname: snameTo.value ? allSname.value : "",
        memo: memoTo.value ? allMemo.value : "",
        funding: fundingTo.value ? allFunding.value : "",
      };
      // 조정된 항목이 없는지 확인
      if (
        !gubunTo.value &&
        !priceTo.value &&
        !snameTo.value &&
        !memoTo.value &&
        !fundingTo.value
      ) {
        alert("조정된 항목이 없습니다.");
        isLoading.value = false;
        return;
      }


      // 선택된 행이 없는지 확인
      if (selectEmp.value.length === 0) {
        alert("선택된 행이 없습니다.");
        isLoading.value = false;
        return;
      }

      // `sname`과 `relAcc` 처리
      if(form.sname){
        form.sname = getScode(form.sname);
      }
      const response = await axios.post(`/api/updateAllInoutData/${mainComp.value}/${mainCompBizGubun.value}/${formatYear(mainStartDate.value)}`, {
        form: form,
        item: selectEmp.value,
      });
      const result = response.data;
      if (result) {
        allGubun.value = "";
        allPrice.value = "";
        allSname.value = "";
        allMemo.value = "";
        allFunding.value = "";
        gubunTo.value = false;
        priceTo.value = false;
        snameTo.value = false;
        memoTo.value = false;
        fundingTo.value = false;
        selectEmp.value = [];

        alert("선택 저장이 완료되었습니다.");
      } else {
        alert("선택 저장에 실패하였습니다.");
      }
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    await getInout();
  }
};

const deleteAllInoutData = async () => {
  isLoading.value = true;

  try {
    const isConfirmed = window.confirm("정말 선택 삭제하시겠습니까?");
    if (isConfirmed) {
      const response = await axios.post(`/api/deleteAllInoutData/${mainComp.value}/${mainCompBizGubun.value}/${formatYear(mainStartDate.value)}`, {
        item: selectEmp.value,
      });
      const result = response.data;
      if (result) {
        allGubun.value = "";
        allPrice.value = "";
        allSname.value = "";
        allMemo.value = "";
        allFunding.value = "";
        gubunTo.value = false;
        priceTo.value = false;
        snameTo.value = false;
        memoTo.value = false;
        fundingTo.value = false;
        selectEmp.value = [];

        alert("선택 삭제가 완료되었습니다.");
      } else {
        alert("선택 삭제에 실패하였습니다.");
      }
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  } finally {
    await getInout();
  }
};

function getScode(sname: string) {
  const item = allComCode.value.find((item: any) => item.sname === sname) as any;
  return item ? item.scode : null;
}

const downloadExcelFile = (fileName: any) => {
  window.location.href = `/api/downloadTemplateExcel/${fileName}`;
};
const getInoutDetailCalc = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/getInoutDetailCalc/${(mainStartDate.value)}/${(mainEndDate.value)}/${mainComp.value}/${mainCompBizGubun.value}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      store.commit('setInoutDetailList', responseData);
      return responseData;
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    isLoading.value = false;
  }
};
onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  selectEmp.value = [];
  await getInout();
  await getAllComCode();

});
</script>
